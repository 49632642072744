import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const MarkedHeader = styled.h1`
  display: inline;

    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      rgba(0, 100, 155, 1),
      rgba(0, 100, 155, 1)
    );
    background-repeat: no-repeat;
    background-size: 100% 0.05em;
    background-position: 0 90%;
  );
`



const HeaderDate = styled.h3`
  margin-top: 10px;
  color: #606060;
`

// STYLE THE TAGS INSIDE THE MARKDOWN HERE
const MarkdownContent = styled.div`
  a {
    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      rgba(0, 100, 155, 0.3),
      rgba(0, 100, 155, 0.6)
    );
    background-repeat: no-repeat;
    background-size: 100% 0.15em;
    background-position: 0 95%;
    transition: background-size 0.25s ease-in;
    &:hover {
      background-size: 100% 88%;
    }
  }

  a > code:hover {
    text-decoration: underline;
  }
  :not(pre)>code[class*=language-], pre[class*=language-] {
    background: #5f5f5f;
}
.gatsby-highlight {
  background: #5f5f5f;
  border-radius: .3em;
  margin: .1em 0;
  padding: 0.5em;
  overflow: auto;
}
code[class*="language-"], pre[class*="language-"] {
  background: #5f5f5f;
  color: #fbfffb;
}
.token.comment {
  color: #d6d6d6;
}
`


const Data = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Content>
        <MarkedHeader>{post.frontmatter.title}</MarkedHeader>
        <HeaderDate>
          {post.frontmatter.date} - {post.timeToRead} min
        </HeaderDate>
        <MarkdownContent dangerouslySetInnerHTML={{ __html: post.html }} />
      </Content>
    </Layout>
  )
}

export default Data

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
      }
      timeToRead
    }
  }
`
